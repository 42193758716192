<template>
    <div>
        <div style="margin-top: 10px;margin-bottom: 0;" class="divider">
                        <span style="color:#666;padding-left: 5px"><v-icon
                                style="color:#666!important">show_chart</v-icon> Monthly Analytics</span>
        </div>

        <v-layout row>
            <v-flex sm2>
                <v-card height="100%" style="border-top:10px solid #ccc;" class="elevation-1" outlined>
                    <div style="margin-top: 0;margin-bottom: 0;" class="divider">
                                <span style="color:#666;padding-left: 5px;font-size: 12px;"><v-icon
                                        style="color:#666!important">person_add</v-icon> Admission</span>
                    </div>
                </v-card>
            </v-flex>
            <v-flex sm2>
                <v-card height="100%" style="border-top:10px solid #ccc;" class="elevation-1" outlined>
                    <div style="margin-top: 0;margin-bottom: 0;" class="divider">
                                <span style="color:#666;padding-left: 5px;font-size: 12px;"><v-icon
                                        style="color:#666!important">person_add_disabled</v-icon> Total Left</span>
                    </div>
                </v-card>
            </v-flex>


            <v-flex sm2>
                <v-card height="100%" style="border-top:10px solid #ccc;" class="elevation-1" outlined>
                    <div style="margin-top: 0;margin-bottom: 0;" class="divider">
                                <span style="color:#666;padding-left: 5px;font-size: 12px;"><v-icon
                                        style="color:#666!important">person_add</v-icon> New Staff</span>
                    </div>
                </v-card>
            </v-flex>

            <v-flex sm2>
                <v-card height="100%" style="border-top:10px solid #ccc;" class="elevation-1" outlined>
                    <div style="margin-top: 0;margin-bottom: 0;" class="divider">
                                <span style="color:#666;padding-left: 5px;font-size: 12px;"><v-icon
                                        style="color:#666!important">file_copy</v-icon> Voucher</span>
                    </div>
                </v-card>
            </v-flex>

            <v-flex sm2>
                <v-card height="100%" style="border-top:10px solid #ccc;" class="elevation-1" outlined>
                    <div style="margin-top: 0;margin-bottom: 0;" class="divider">
                                <span style="color:#666;padding-left: 5px;font-size: 12px;"><v-icon
                                        style="color:#666!important">local_library</v-icon> Events</span>
                    </div>
                </v-card>
            </v-flex>

            <v-flex sm2>
                <v-card height="100%" style="border-top:10px solid #ccc;" class="elevation-1" outlined>
                    <div style="margin-top: 0;margin-bottom: 0;" class="divider">
                                <span style="color:#666;padding-left: 5px;font-size: 12px;"><v-icon
                                        style="color:#666!important">sms</v-icon> SMS Sent</span>
                    </div>
                </v-card>
            </v-flex>

        </v-layout>
    </div>
</template>

<script>
    export default {}
</script>