<template>
  <v-container grid-list-md pa-0 relative>
    <v-layout row wrap>
      <v-flex xs12>
        <v-layout row>
          <v-flex sm8>
            <auth-info></auth-info>
          </v-flex>
          <v-flex sm4>
            <member-count></member-count>
          </v-flex>
        </v-layout>
        <br />

        <monthly-analytic></monthly-analytic>

        <br />

        <div style="margin-top: 10px; margin-bottom: 0" class="divider">
          <span style="color: #666; padding-left: 5px"
            ><v-icon>calendar_today</v-icon> School Calendar</span
          >
        </div>
        <div class="calendar-dash">
          <calendar :handler="false"></calendar>
        </div>
        <br />

        <!--<buttom-card></buttom-card>-->
      </v-flex>
    </v-layout>
  </v-container>
</template>
<script>
import AuthInfo from "./../blocks/authinfo";
import MemberCount from "./../blocks/membercount";
// import DashboardCarousel from "./../blocks/dashboardcarousel";

// import GradePie from './../blocks/gradepie'
// import CollectionDonut from './../blocks/collectiondonut'
// import DailyAttendance from './../blocks/dailyattendance'
// import AnnualCollection from './../blocks/annualcollection'
import MonthlyAnalytic from "./../blocks/monthlyanalytic";
// import TeacherRoutine from './../blocks/teacherroutine'
import Calendar from "./../../../calendar/_components/Calendar/index";
// import ButtomCard from './../blocks/buttomcard'

export default {
  components: {
    AuthInfo,
    MemberCount,
    // DashboardCarousel,
    // GradePie,
    // CollectionDonut,
    // DailyAttendance,
    // AnnualCollection,
    // TeacherRoutine,
    // ButtomCard,
    MonthlyAnalytic,
    Calendar,
  },
  data: () => ({}),
  computed: {
    // ...mapState(['user']),
  },
  mounted() {
    this.$auth.getServices("ACCOUNT");
    // this.getWeeklyCollectionData();
  },
  methods: {
    viewProfile() {
      this.$router.push({ name: "my-account" });
    },
    calendarView() {
      this.$router.push({ name: "calendar" });
    },
    dueCollectionView() {
      this.$router.push({ name: "collection-due" });
    },
  },

  beforeDestroy() {
    // this.$events.remove('notification')
  },
};
</script>
<style lang="scss">
.calendar-dash {
  .head_title {
    background-color: transparent !important;
  }
  .table {
    background-color: transparent !important;
  }
  .data-represent {
    margin-left: 0;
  }
}

.apexcharts-canvas {
  width: 100% !important;
}

.logged-in-info {
  position: relative;
  /*background: red;*/
  max-width: 500px;
  display: flex;

  .profile {
    margin-right: 10px;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    overflow: hidden;
    border: 1px solid #9ba3ad;

    img {
      width: 100%;
    }
  }

  .pro-info {
    width: 70%;

    h4 {
      font-size: 12px;
      color: #c0c0c0;
      line-height: 1;
      margin-top: 0;
      display: block;
      width: 100%;

      i {
        font-size: 12px;
      }

      span.logged_in {
        float: right;
        font-weight: normal;

        strong {
          color: #888;
        }

        /*text-align: right;*/
      }
    }

    h2 {
      color: #172c44;
      font-size: 16px;
      opacity: 0.8;
      line-height: 0.8;

      + p {
        color: #999;
        font-size: 12px;
      }
    }
  }
}

/*<div class="representation">*/
/*</div>*/
/*<div class="member-label">*/
/*<h3>Total Member</h3>*/
/*<p>Male 201, Female 301</p>*/
/*</div>*/
/*<div class="counter">*/
/*<h2>304</h2>*/
/*</div>*/

.board-static-rep {
  position: absolute;
  right: -20px;
  opacity: 0.9;
  width: 300px;
  top: 0;
}

.school_info {
  position: relative;

  h1 {
    color: #172c44;
    font-weight: 500;
    font-size: 24px;
  }

  .icon-info {
    a {
      text-decoration: none;
      font-size: 13px;
      color: #758392;
      display: inline-block;
      margin-right: 20px;

      i {
        font-size: 16px;
        color: #758392;
      }

      &.disabled {
        opacity: 0.3;
      }

      &.facebook {
        color: #4267b2;

        i {
          color: #4267b2;
        }
      }

      &.instagram {
        color: #d82b7d;

        i {
          color: #d82b7d;
        }
      }

      &.twitter {
        color: rgb(29, 161, 242);

        i {
          color: rgb(29, 161, 242);
        }
      }
    }
  }
}

.dash-container {
  /*padding: 0 !important;*/
  .info-tab-section {
    padding-bottom: 20px;

    .theme--light {
      background-color: transparent !important;
    }
  }
}

.v-tabs__item {
  color: #888;
}

.v-tabs__item--active {
  color: #1976d2 !important;
  font-weight: bold;
}

.chart-container {
  padding: 20px 0 !important;
  margin-left: -60px;
  min-height: 330px;
}

.app-features {
  display: flex;
  min-height: 120px;
  background-color: white;
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.1);

  .feature-icon {
    flex: 1.3;
    align-items: center;
    justify-content: center;
    display: flex;
    /*background-color: red;*/
  }

  .feature-content {
    flex: 3;
    background-color: white;
    /*padding: 15px 15px;*/
    display: flex;
    align-items: center;
    justify-content: center;

    .content {
      p {
        color: #777;
        line-height: 15px;
        font-size: 13px;
      }
    }

    h3 {
      font-size: 18px;
    }

    h2 {
      color: #555;
      line-height: 14px;
      font-size: 20px;
      font-weight: 300;
    }
  }

  &.f-books {
    .feature-icon {
      background: -webkit-linear-gradient(45deg, #ff5252 0%, #f48fb1 100%);
      background: linear-gradient(45deg, #ff5252 0%, #f48fb1 100%);
    }

    h3 {
      color: #ff5252;
    }
  }

  &.f-sms {
    .feature-icon {
      background: -webkit-linear-gradient(45deg, #43a047 0%, #1de9b6 100%);
      background: linear-gradient(45deg, #43a047 0%, #1de9b6 100%);
    }

    h3 {
      color: #43a047;
    }
  }

  &.f-converter {
    .feature-icon {
      background: -webkit-linear-gradient(45deg, #0288d1 0%, #26c6da 100%);
      background: linear-gradient(45deg, #0288d1 0%, #26c6da 100%);
    }

    h3 {
      color: #0288d1;
    }
  }

  &.f-collection {
    .feature-icon {
      background: -webkit-linear-gradient(45deg, #b199dd 0%, #a389d3 100%);
      background: linear-gradient(45deg, #b199dd 0%, #a389d3 100%);
    }

    h3 {
      color: #a389d3;
    }
  }
}

.background-amount {
  background: white;
  background: -webkit-linear-gradient(45deg, white 0%, #dddddd 100%);
  background: linear-gradient(45deg, white 0%, #dddddd 100%);
}

.background-converter {
  background: #ff5252;
  background: -webkit-linear-gradient(45deg, #ff5252 0%, #f48fb1 100%);
  background: linear-gradient(45deg, #ff5252 0%, #f48fb1 100%);
}

.background-books {
  background: #0288d1;
  background: -webkit-linear-gradient(45deg, #0288d1 0%, #26c6da 100%);
  background: linear-gradient(45deg, #0288d1 0%, #26c6da 100%);
}

.background-sms {
  background: #43a047;
  background: -webkit-linear-gradient(45deg, #43a047 0%, #1de9b6 100%);
  background: linear-gradient(45deg, #43a047 0%, #1de9b6 100%);
}

.dash-container {
  min-height: 100px;
  width: 90%;
  /*padding: 40px 0 0 0;*/
  margin: 0 auto;

  div.dash-company-info {
    padding-top: 20px;
    display: flex;
    padding-bottom: 10px;
  }

  h1 {
    color: #555;
    font-weight: 300;

    + p {
      color: #888;
      font-size: 14px;
    }
  }

  .navigate-to {
    flex: 1.5;
    padding-top: 15px;

    ul li {
      list-style: none;
      display: inline;

      a {
        display: inline-block;
        border: 0.15em solid #c9c9c9;
        margin-right: 10px;
        height: 25px;
        font-size: 10px;
        width: 25px;
        text-align: center;
        line-height: 25px;
        border-radius: 50px;
        text-decoration: none;

        &:hover {
          i {
            color: #333 !important;
          }
        }
      }
    }
  }

  .intro {
    flex: 3;
  }
}

.profile-user-info {
  display: flex;

  .profile-image {
    box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2);
    border-radius: 200px;
    overflow: hidden;
    flex: 1;
    border: 1px solid white;

    img {
      margin: 0 auto 0 -10px;
      padding: 0;
      width: 118%;
    }

    width: 100px;
    height: 106px;
    /*background-color: red;*/
  }

  .profile-info {
    ul {
      margin: 0;
      padding: 0;
    }

    flex: 2;
    color: #555;
    padding-left: 15px;

    h3 {
      font-weight: normal;
      color: #333;
    }

    p {
      line-height: 15px;
    }

    .name-info {
      font-size: 14px;
      margin-bottom: 5px;
      color: #888;
      font-weight: bold;
      line-height: 10px;
      margin-bottom: 10px;
    }

    .email-info {
      color: #999999;
      font-size: 12px;
      padding-top: 0;
      margin-bottom: 10px;
    }

    .profile-nav {
      padding-top: 10px;
      border-top: 1px solid #e3e3e3;
    }

    /*background-color: green;*/
  }
}
</style>